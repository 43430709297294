// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-mission-js": () => import("./../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-service-js": () => import("./../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-use-cases-js": () => import("./../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-why-smartchain-js": () => import("./../src/pages/why-smartchain.js" /* webpackChunkName: "component---src-pages-why-smartchain-js" */)
}

